<template>
  <div class="root">
    <lottery
      class="lottery"
      :round="round"
      :key="datekey"
      :data="roundParm.data"
      :res="roundParm.res"
      :playTime="playTime"
      :length="length"
      @scrollStop="onStop"
      @speedChange="onSpeedChange"
      @playPkAudio="playPkAudio"
    >
    </lottery>
  </div>
</template>

<script>
import lottery from "@/components/pkHome/lottery.vue";

export default {
  data() {
    return {
      playTime: this.singlePlayTime,
      // 一个人本局游戏所有数据
      allRecord: this.records,
      // 每回合参数
      roundParm: {
        data: [],
        res: "-1",
      },
      // 当前回合数
      round: this.currentRound,
      // 用于刷新子组件
      datekey: Date.now(),
      // debug,目标位置
      resLocation: new Map(),
      pkAudio:new Audio(require('../../assets/1.mp3'))
    };
  },
  watch: {},
  methods: {
    
    playPkAudio(){
      this.pkAudio.play()
    },
    onSpeedChange(speed) {
      this.$emit("speedChange", speed);
    },
    locationChange(LocationMap) {
      //console.log("位置改变 " + JSON.stringify(L));
      this.$emit("locationChange", LocationMap);
      this.resLocation = LocationMap;
    },
    onStop(result) {
      console.log('动画结束');
      console.log('this.round',this.round,this.allRecord);
      // 产生结果
      this.$emit("produceResult", result);
      // 更新参数
      //console.log("当前回合=" + this.round);
      // if (this.round == 0) {
      //   this.round++;
      //   // setTimeout(() => {
      //   //   this.datekey = Date.now(); // 重载lottery组件
      //   // }, 1000);
      //   // return;
      // }
      if (this.round >= this.allRecord.length) {
        console.log('执行结束函数');
        this.$emit("allEnd");
        return;
      }
      // this.round++;
      this.roundParm.data = this.allRecord[this.round].boxInfo.ornaments
      // console.log('fun',this.roundParm.data);
      this.roundParm.res = this.allRecord[this.round].ornamentId + "";
      // console.log("stopres", this.roundParm.res);
      // console.log("round", this.round);
      this.round++;
      // for (let index = 0; index < this.allRecord.length; index++) {
      //   if (this.round == 0) {
      //     this.round++;
      //     continue;
      //   }
      //   if (this.round >= this.allRecord.length) {
      //     break;
      //   }
      //   this.roundParm.data = this.allRecord[this.round].boxInfo.ornaments;
      //   this.roundParm.res = this.allRecord[this.round].ornamentsId + "";
      //   this.round++;
      // }
      setTimeout(() => {
        this.$bus.$emit("num", this.round);
        this.datekey = Date.now(); // 重载lottery组件
      }, 1000);
    },
  },
  props: {
    records: Array,
    singlePlayTime: Number,
    currentRound: Number,
    length:Number

  },
  components: {
    lottery,
  },
  beforeMount() {
    // this.playTime = this.singlePlayTime;
  },
  mounted() {
    this.$bus.$emit("num", this.round);
    // 第一次渲染
    if(this.round==-1){
      return false
    }
    this.roundParm.data = this.allRecord[this.round-1].boxInfo.ornaments;
    this.roundParm.res = this.allRecord[this.round-1].ornamentId + "";
    // console.log("round", this.round);
    // console.log("allRecord", this.allRecord);
    // console.log("records", this.records);
    // console.log("currentRound", this.currentRound);
    // console.log("res", this.roundParm.res);
    // console.log('');
    // 重载lottery组件
    setTimeout(() => {
      this.datekey = Date.now(); // 重载lottery组件
    }, 100);
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.root {
  width: 100%;
  height: 100%;
  position: absolute;
  // border: 3px solid blue;
  .lottery {
    position: relative;
    // width: 100%;
    height: 100%;
  }
}
</style>
